#resourceHubLabel {
    font-size: 18px;
    font-weight: 400;
    color: inherit;
    margin-bottom: 10px;
}

.resourceHub{
    margin : 4px 10px;
}

.resourceHub input{
    border: 1px;
}

form.common-form .custom-btn  {
    height: 60px;
}
.click-url {
    text-decoration: underline;
    color: #0055AA;
    cursor: pointer;
    transition: color 0.3s;
}

.click-url:hover {
    color: #121f48;
}

  
.modal-body {
padding: 20px;
background: #f9f9f9;
}

.custom-dialog .modal-body p{
font-size: 1rem;
}

.location-details h3 {
font-size: 1.2rem;
font-weight: bold;
margin-top: 15px;
color: #222;
border-bottom: 2px solid #ddd;
padding-bottom: 5px;
}

.info {
background: #fff;
padding: 10px;
margin-bottom: 10px;
border-radius: 8px;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.resourceHub .table-wrap table td {
    font-size: 1rem;
}

.modal-90w {
    width: 90vw !important; /* Ensures modal width */
    max-width: 90vw !important;
}
